import { createContext, useEffect, useState } from 'react'
import type { PropsWithChildren } from 'react'

import { useAuthentication } from '@motrix/authentication'
import type { DebugValue } from '@motrix/core'
import { DebugToolBar } from '@motrix/core'

import WorkspaceService from '../services/workspace/workspace.service'

interface DebugToolBarProviderData {
  debugData: Array<DebugValue>
  envData: Array<DebugValue>
  isDev: boolean
}

const DebugToolBarContext = createContext<DebugToolBarProviderData>(
  {} as DebugToolBarProviderData,
)

export const DebugToolBarProvider = ({ children }: PropsWithChildren) => {
  const {
    me: { accessToken },
  } = useAuthentication()
  const [debugData] = useState([
    { name: 'AccessToken', value: accessToken },
    { name: 'API_URL', value: process.env.API_URL },
    {
      name: 'MAINTENANCE_MODE',
      value: process.env.MAINTENANCE_MODE ? 'OFF' : 'ON',
    },
    { name: 'SITE_URL', value: process.env.SITE_URL },
  ])

  const [envData] = useState([
    { name: 'COGNITO_USER_POOL_ID', value: process.env.COGNITO_USER_POOL_ID },
    {
      name: 'COGNITO_USER_POOL_NAME',
      value: process.env.COGNITO_USER_POOL_NAME,
    },
    { name: 'COGNITO_CLIENT_ID', value: process.env.COGNITO_CLIENT_ID },
    { name: 'COGNITO_URL', value: process.env.COGNITO_URL },
  ])

  //todo: change to validate actions
  const [isDev, setIsDev] = useState(false)

  useEffect(() => {
    const fetchMe = async () => {
      const result = await WorkspaceService.getMe()
      if (!result) return
      setIsDev(
        result?.memberships?.[0]?.roles?.some(
          (role) => role.value === 'motrix-dev',
        ),
      )
    }
    fetchMe()
  }, [])

  return (
    <DebugToolBarContext.Provider value={{ debugData, envData, isDev }}>
      {children}
      <DebugToolBar
        showToolbar={isDev}
        info={[{ data: debugData }]}
        envs={[{ data: envData }]}
      />
    </DebugToolBarContext.Provider>
  )
}

export default DebugToolBarProvider
