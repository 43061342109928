import { Amplify, Auth, I18n } from 'aws-amplify'

import { translations } from '@aws-amplify/ui-react'

I18n.putVocabularies(translations)
I18n.putVocabularies({
  pt: {
    'Enter your Password': 'Insira sua senha',
    'Enter your Username': 'Insira seu usuário',
    'Enter your username': 'Email',
    'Incorrect username or password.': 'Usuário e/ou senha inválidos.',
    'Reset Password': 'Esqueceu sua senha?',
    Username: 'Email',
  },
})
I18n.setLanguage('pt')

Amplify.configure({
  ssr: true,
})

let domain =
  process.env.NODE_ENV === 'development' ? 'localhost' : 'd.motrix.global'
switch (process.env.APP_ENV) {
  case 'production':
    domain = 'motrix.global'
    break
  case 'staging':
    domain = 's.motrix.global'
    break
}

Auth.configure({
  cookieStorage: {
    domain,
    expires: 365,
    path: '/',
    secure: process.env.NODE_ENV === 'production',
  },
  region: process.env.AWS_REGION ?? import.meta.env.AWS_REGION,
  userPoolId:
    process.env.COGNITO_USER_POOL_ID ?? import.meta.env.COGNITO_USER_POOL_ID,

  userPoolWebClientId:
    process.env.COGNITO_CLIENT_ID ?? import.meta.env.COGNITO_CLIENT_ID,
})
